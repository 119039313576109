/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #102535; // dark blue
	--color2:               #BA3926; // orange
	--color3:               #105489; // light blue, hover
	--color4:               #DF4B36; // light orange, hover
	--color5:               #081621; // extra dark blue, menu bg
	--color6:               #F0F2F6; // pale blue, bg

	// TYPOGRAPHY
	// ---------------

	--text-color:            #3F505D;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Montserrat", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--color1);
	--hr-color:              #CFD3D6;

}